<!-- card table info -->
<template>
  <div>
    <div v-for="(card, index) in data" :key="index" style="display: inline-block !important">
      <vs-button
        v-b-tooltip="{
          title: `${card.tooltip}`,
          placement: 'top',

          customClass: 'font_tooltip',
        }"
        icon
        @click="toggleActive(index)"
        :color="card.type"
        class="mx-1 px-0"
        :key="index"
        :style="{ boxShadow: activeCard === index ? `inset 0 0 0 2px ${card.color}` : 'none' }"
      >
        <!-- linar-style cursor glow -->
        <i :class="`fas fa-${card.iconName} }`"></i>
      </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [
        {
          title: "Título",
          value: 0,
          iconName: "crown",
          color: "rgba(223, 18, 46, 1)",
          tipo: "circle",
          animated: "shake",
          onActivate: null,
          onDeactivate: null,
        },
      ],
    },
    Id: null,
    dataModal: {},
  },
  data() {
    return {
      showGlow: {},
      cursorX: {},
      cursorY: {},
      animationFrameId: {},
      animatedState: {},
      activeCard: null,
      shouldAnimateTitle: {},
      animated: {
        beat: "fa-beat",
        beatFade: "fa-beat-fade",
        bounce: "fa-bounce",
        fade: "fa-fade",
        flip: "fa-flip",
        shake: "fa-shake",
        spin: "fa-spin",
        spinReverse: "fa-spin fa-spin-reverse",
        spinPulse: "fa-spin-pulse",
      },
      tipoIcon: {
        circle: "50%",
        squared: "20%",
        shield: "49% 51% 48% 52% / 29% 29% 71% 71%",
        water: "60% 40% 78% 22% / 73% 66% 34% 27%",
      },
    };
  },
  /*
Eventos de Ciclo de Vida:
- mounted: Se añade un escuchador para el evento de redimensionamiento de la ventana.
- beforeDestroy: Se elimina el escuchador para el evento de redimensionamiento de la ventana.
*/
  mounted() {
    // alert(this.data[0].value)
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.data.forEach((_, index) => {
        this.checkTitleLength(index);
      });
    },
    checkTitleLength(index) {
      this.$nextTick(() => {
        const titleElement = this.$el.querySelectorAll(".title-container span")[index];
        if (titleElement) {
          // console.log("Client Width: ", titleElement.clientWidth);
          // console.log("Scroll Width: ", titleElement.scrollWidth);

          if (titleElement.scrollWidth > titleElement.clientWidth) {
            this.$set(this.shouldAnimateTitle, index, true);
          } else {
            this.$set(this.shouldAnimateTitle, index, false);
          }
        }
      });
    },
    toggleActive(index) {
      console.log(this.data);
      console.log("data modal --->", index);
      console.log("data value --->", this.data[index].value);
      if (this.data[index].value == 999) {
        console.log("data -1");
        this.data[index].onActivate(this.dataModal);
      } else {
        console.log("data <> 1");
        this.data[index].onActivate(this.Id);
      }

      // Desactivar tarjeta anterior si es diferente de la actual
      // if (this.activeCard !== null && this.activeCard !== index) {
      //   if (typeof this.data[this.activeCard].onDeactivate === "function") {
      //     this.data[this.activeCard].onDeactivate();
      //   }
      // }

      // // Cambiar la tarjeta activa
      // this.activeCard = this.activeCard === index ? null : index;

      // // Ejecutar la función de activación o desactivación correspondiente
      // if (this.activeCard !== null) {
      //   if (typeof this.data[this.activeCard].onActivate === "function") {
      //     this.data[this.activeCard].onActivate();
      //   }
      // } else {
      //   if (typeof this.data[index].onDeactivate === "function") {
      //     this.data[index].onDeactivate();
      //   }
      // }
    },
    computeBorderRadius(tipo) {
      return this.tipoIcon[tipo] || "20% 40% 20% 40%";
    },
    computeAnimateIcon(tipo, index) {
      if (this.animatedState[index]) {
        return this.animated[tipo] || "";
      }
    },
    evaluateValue(value) {
      if (typeof value === "function") {
        return value();
      }
      return value;
    },
    glowStyle(color) {
      return {
        boxShadow: `0 0 40px 20px ${color}`,
      };
    },
    handleMouseMove(event, index) {
      const rect = event.currentTarget.getBoundingClientRect();
      const targetX = event.clientX - rect.left;
      const targetY = event.clientY - rect.top;

      // Cancelar la animación anterior si está en curso
      if (this.animationFrameId[index]) {
        cancelAnimationFrame(this.animationFrameId[index]);
      }

      // Iniciar una nueva animación
      const animate = () => {
        const startX = this.cursorX[index] || 0;
        const startY = this.cursorY[index] || 0;
        const dx = (targetX - startX) * 0.1; // Factor de suavizado
        const dy = (targetY - startY) * 0.1; // Factor de suavizado

        this.$set(this.cursorX, index, startX + dx);
        this.$set(this.cursorY, index, startY + dy);

        if (Math.abs(dx) > 0.1 || Math.abs(dy) > 0.1) {
          // Continuar la animación
          this.animationFrameId[index] = requestAnimationFrame(animate);
        }
      };

      // Iniciar la animación
      this.animationFrameId[index] = requestAnimationFrame(animate);
    },
    showCursorGlow(index) {
      this.$set(this.animatedState, index, true);
      this.$set(this.showGlow, index, true);
    },
    hideCursorGlow(index) {
      this.$set(this.animatedState, index, false);
      this.$set(this.showGlow, index, false);
    },
  },
};
</script>

<style scoped>
.font_tooltip {
  font-size: 14px;
  font-style: italic;
}
</style>
